import React, {useEffect, useState, useRef, useReducer} from 'react'
import styled from 'styled-components'
import MapSideBar from '../components/MapSideBar'
import mapboxgl from '!mapbox-gl'
import axios from 'axios'
import proj4 from 'proj4'
import {base_url, aws_url} from '../config/config'
import UpArrow from '../assets/icons/up-50.png'
import DownArrow from '../assets/icons/down-50.png'
import LeftArrow from '../assets/icons/left-50.png'
import RightArrow from '../assets/icons/right-50.png'
import DestinationParkIcon from '../assets/icons/parks-button-icon.png';
import YouAreHere from '../assets/icons/you-are-here.png';
import TrailIcon from '../assets/icons/trail_icon.png';
import NorthArrowIcon from '../assets/icons/north-arrow.png';
import CivicIcon from '../assets/icons/civic.png';
import POIIcon from '../assets/icons/point.png';
import ParkRecIcon from '../assets/icons/parks_rec.png';
import BikeLane from '../assets/trails/bike/BikeLane.geojson';
import ProtectedBikeLane from '../assets/trails/bike/ProtectedBikeLane.geojson';
import PaveShoulderBikeRoute from '../assets/trails/bike/PavedShoulder.geojson';

import EmbarkTransitRoute from '../assets/trails/transit/EMBARK_TransitRoutes.geojson';
import StreetCarRoutes from '../assets/trails/transit/StreetCarRoutes.geojson';


mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXNkZWNpYmVsa2MiLCJhIjoiY2wwdjE5OGQ4MTMzdDNrcXA1MXF6ZDV1YiJ9.oqXkqdL6J3iDkAW68qQp1w'

let moving = false;

const MapContainer = ({latitude, longitude}) => {

    const mapContainer = useRef(null)
    const map = useRef(null)
    const markers = useRef([])
    const liveAssets = useRef([])
    const filteredMarkers = useRef([])
    const filteredLiveAssets = useRef([])
    const trailsArr = useRef([])
    const trailIds = useRef([])
    const oldZoom = useRef([])
    const leftOffsiteTrailsArr = useRef([])
    const rightOffsiteTrailsArr = useRef([])
    const topOffsiteTrailsArr = useRef([])
    const bottomOffsiteTrailsArr = useRef([])

    const bikesArr = useRef([])
    const bikeIds = useRef([])
    const transitsArr = useRef([])
    const transitIds = useRef([])
    const streetCarArr = useRef([])
    const streetCarIds = useRef([])


    const [isOpen, setIsOpen] = useState(false)
    const [sideBarData, setSideBarData] = useState('')
    const [positionWatcher, setPositionWatcher] = useState(null)
    const [userCurrentPosA, setUserCurrentPosA] = useState(null)
    const [userCurrentPosB, setUserCurrentPosB] = useState(null)
    const [searchSelected, setSearchSeleted] = useState(null)
    const [triggerRerender, setTriggerRerender] = useState(0);
    const [onTrailMarkers, setOnTrailMarkers] = useState([]);

    const [showOffSiteLeftTrail, setShowOffSiteLeftTrail] = useState(false);
    const [showOffSiteRightTrail, setShowOffSiteRightTrail] = useState(false);
    const [showOffSiteTopTrail, setShowOffSiteTopTrail] = useState(false);
    const [showOffSiteBottomTrail, setShowOffSiteBottomTrail] = useState(false);

    const [zoom, setZoom] = useState(1)
    const [triggerMaps, setTriggerMaps] = useState(false);

    //as per documentaiton this is proper boundry
    //https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson
    const [bounds, setBounds] = useState([[-97.70222093552515, 35.30763136430207], [-97.3181268501335, 35.61020632178125]])
    // const [bounds2, setBounds2] = useState([[-97.70202347954994, 35.38619212815916], [-97.51006555011654, 35.444798580123475]]);

    // const [bounds, setBounds] = useState([
    //     [ParseDMS(`97°41'72"W 35°18'25"N`)[0], ParseDMS(`97°41'72"W 35°18'25"N`)[1]],
    //     [ParseDMS(`97°18'42"W 35°36'38"N`)[0], ParseDMS(`97°18'42"W 35°36'38"N`)[1]]
    // ])

    // 38° 49' 22.1952'' N  94° 42' 52.6536'' W
    //
    // 38° 49' 18.6744'' N 94° 42' 48.2904'' W

    const [minZoom, setMinZoom] = useState(1)
    const [maxZoom, setMaxZoom] = useState(20)

    const [oldIimageCoordinates, setImageCoordinates] = useState([
        [-97.7, 35.672222],
        [-97.311111, 35.670556],
        [-97.314722, 35.248611],
        [-97.701389, 35.250278]
    ])

    // NW Upper Left: 97°41'36"W 35°36'5"N
    // NE Upper Right: 97°18'17"W 35°35'59"N
    // SW Lower Left: 97°41'40"W 35°18'36"N
    // SE Lower Right: 97°18'27"W 35°18'31"N

    // required coords for fair shape. tilted image otherwise
    // const imageCoordinates = useState([ParseDMS(`97°41'40"W 35°36'5"N`), ParseDMS(`97°18'27"W 35°36'5"N`), ParseDMS(`97°18'27"W 35°18'31"N`), ParseDMS(`97°41'40"W 35°18'31"N`)])
    // new coords from trello
    
    const imageCoordinates_lvl1 = useState([ParseDMS(`97°41'67"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°18'15"N`), ParseDMS(`97°41'72"W 35°18'25"N`)])
    // const imageCoordinates_lvl2 = useState([ParseDMS(`97°41'67"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°18'15"N`), ParseDMS(`97°41'72"W 35°18'25"N`)])
    // const imageCoordinates_lvl3 = useState([ParseDMS(`97°41'67"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°36'38"N`), ParseDMS(`97°18'53"W 35°18'15"N`), ParseDMS(`97°41'72"W 35°18'25"N`)])

    // const imageCoordinates_lvl2 = useState([ParseDMS(`97°41'74"W 35°36'43"N`), ParseDMS(`97°18'34"W 35°36'40"N`), ParseDMS(`97°18'47"W 35°18'10"N`), ParseDMS(`97°41'72"W 35°18'20"N`)])

    // const imageCoordinates_lvl3 = useState([ParseDMS(`97°41'74"W 35°36'43"N`), ParseDMS(`97°18'20"W 35°36'48"N`), ParseDMS(`97°18'47"W 35°18'10"N`), ParseDMS(`97°41'65"W 35°18'25"N`)])

    useEffect(() => {
        if (map.current) {
            return
        }

        generateMap()
    }, [map])

    //You are here marker replacement
    useEffect(() => {
        if(userCurrentPosA){
            if(userCurrentPosB) {
                userCurrentPosB.remove();
            }
            setUserCurrentPosB(userCurrentPosA)
        }
    }, [userCurrentPosA])

    useEffect(() => {
        if (trailsArr.current) {
            setTimeout(function() {
                showTrailsOnMap(true).then(() => {
                    generateTrailOutlines(true);
                    updateOffsiteTrails();
                });
            },1000);
            setTriggerMaps(false);
        }
    }, [trailsArr.current, triggerMaps])

    const setSearchSelected = (value) => {
            setSearchSeleted(value);
    }

    //You are here marker replacement
    useEffect(() => {
        if(searchSelected) {
            // console.log("Need to center lat,long" + JSON.stringify(searchSelected));

            if(searchSelected.longitude && searchSelected.latitude){
                map.current.flyTo({
                    center: [searchSelected.longitude, searchSelected.latitude]
                })
            } else { // Trail has different JSON body
                map.current.flyTo({
                    center: [searchSelected.startPointMarker.lng, searchSelected.startPointMarker.lat]
                })
            }

            openSideBar(searchSelected);
        }

    }, [searchSelected])


    const forceUpdate = useReducer(x => x + 1, 0)[1]

    const updateOffsiteTrails = () => {
        leftOffsiteTrailsArr.current = []
        rightOffsiteTrailsArr.current = []
        topOffsiteTrailsArr.current = []
        bottomOffsiteTrailsArr.current = []
        updateTrailIndicators();

    }

    const centerMap = (long, lat) => {
        map.current.flyTo({
            center: [long, lat]
        })
    }


    const updateTrailIndicators = () => {
        // console.log('update trail indicators');
        const features = map.current.queryRenderedFeatures();
        let viewable_trail_features = [];
        features.forEach((feature) => {
            if (feature.layer && feature.layer.id && feature.layer.id.indexOf('route') > -1) {
                let pos1 = feature.layer.id.indexOf('-') + 1;
                let pos2 = feature.layer.id.lastIndexOf('-');
                let trail_name = feature.layer.id.substring(pos1, pos2);

                if (!viewable_trail_features.includes(trail_name)) {
                    return viewable_trail_features.push(trail_name);
                }
            }
        })

        // Offsite Trail
        let offsite_trails = trailsArr.current.filter((trail) => {
            return !viewable_trail_features.includes(trail[0]._id);
        })

        // console.log(offsite_trails.length+' offsite trails');

        offsite_trails.forEach((trail) => {
            const direction = getTrailDirection(trail);
            createOffsiteTrailName(trail, direction);
        })

        // Onsite Trails
        let onsite_trails = trailsArr.current.filter((trail) => {
            return viewable_trail_features.includes(trail[0]._id);
        })
        // console.log('onsite trails', onsite_trails);
        let map_bounds = map.current.getBounds()

        onsite_trails.forEach((trail) => {
            let coord_in_bounds = false;

            //Added geometry to json object
            features.forEach((feature) => {
                if (feature.layer && feature.layer.id && feature.layer.id.indexOf('route') > -1) {
                    if(feature.layer.id.includes(trail[0]._id)){
                        trail[0].geometry = feature.geometry;
                    }
                }
            })

            // remove old trail markers
            // console.log('onTrailMarkers', onTrailMarkers);
            if(onTrailMarkers[trail[0].name]) {
                onTrailMarkers[trail[0].name].forEach((marker) =>{
                    marker.remove();
                })
            }

            //set inbounds trail markers
            coord_in_bounds = false;

            const el1 = document.createElement('div');
            let height = 30;

            el1.className = 'marker';
            el1.style.backgroundImage = `url(${TrailIcon})`;
            el1.style.verticalAlign ='middle';
            el1.style.lineHeight = `${height}px`;
            el1.style.height = `${height}px`;
            // el1.style.height = `100px`;
            el1.style.width = `50px`;
            el1.style.backgroundSize = '50px';
            el1.style.backgroundColor = '#91BD55';
            el1.style.backgroundPosition = 'center left';
            el1.style.backgroundRepeat = 'no-repeat';
            el1.style.padding ='0px 10px 0px 40px';
            el1.style.boxShadow ="2px 2px #808080";
            el1.style.borderRadius = "5px";
            el1.style.cursor = 'pointer'
            el1.style.zIndex = '3';

            const el2 = document.createElement('div');
            el2.className = 'marker';
            el2.textContent = `${trail[0].name}`;
            el2.style.color = 'white';
            el2.style.textAlign = 'center';
            el2.style.verticalAlign ='middle';
            el2.style.lineHeight = `${height}px`;
            el2.style.fontSize = '1.25em';
            el2.style.fontWeight = '700';
            el2.style.textShadow = "2px 2px #808080";
            el2.style.height = `${height}px`;
            el2.style.backgroundColor = '#91BD55';
            el2.style.padding ='0 10px';
            el2.style.boxShadow ="2px 2px #808080";
            el2.style.borderRadius = "5px";
            el2.style.cursor = 'pointer'
            el2.style.zIndex = '3';

            // if(trail[0].geometry.type == "MultiLineString"){
            //     trail[0].geometry.coordinates.forEach((coordinate) => {
            //             coordinate.forEach((coord) => {
            //                 console.log('trail name MLS', trail[0].name);
            //                 if (inBounds(coord, bounds) && !coord_in_bounds) {

            //                     let latitude = coord[1];
            //                     let longitude = coord[0];
            //                     let onTrails = Object.keys(onTrailMarkers);
            //                     var markerHeight = 35;
            //                     let offset = 0;
            //                     for (let i = 0; i < onTrails.length; i++) {
            //                         if (trail[0].name == onTrails[i]) {
            //                             continue;
            //                         }
            //                         // console.log('testing trail', trail[0].name);
            //                         // console.log('trail: ',onTrails[i], 'coords: ',onTrailMarkers[onTrails[i]][0].getLngLat());
            //                         // console.log('point: ', map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()));
            //                         // console.log('current point: ', map.current.project([longitude, latitude]));
            //                         // console.log('point:', map.current.project([longitude, latitude]).y, 'compare:',map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y);
            //                         let currentPoint = map.current.project([longitude, latitude]).y;
            //                         let testPoint = map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y;
            //                         if (map.current.project([longitude, latitude]).y + markerHeight >= map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y) {
            //                             console.log('trail name', trail[0].name, 'overlaps',onTrails[i]);
            //                             if (currentPoint + (markerHeight / 2) >= testPoint) {
            //                                 console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'top');
            //                                 offset = markerHeight;
            //                             }
            //                             if (currentPoint - (markerHeight / 2) <= testPoint) {
            //                                 console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'bottom');
            //                                 offset = -1 * markerHeight;
            //                             }
            //                             if (currentPoint + (markerHeight / 2) >= testPoint && currentPoint - (markerHeight / 2) <= testPoint) {
            //                                 console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'all');
            //                                 offset = 2 * markerHeight;
            //                             }
            //                         }

            //                     }
            //                     let marker1 = new mapboxgl.Marker(el1).setLngLat([longitude, latitude]);
            //                     marker1.addTo(map.current);
            //                     marker1.setOffset([el1.clientWidth / 2 + 20, offset]);
            //                     marker1.getElement().addEventListener('click', () => {
            //                         openSideBar(trail[0])
            //                     });

            //                     let marker2 = new mapboxgl.Marker(el2).setLngLat([longitude, latitude]);
            //                     marker2.addTo(map.current);
            //                     marker2.getElement().addEventListener('click', () => {
            //                         openSideBar(trail[0])
            //                     });

            //                     marker2.setOffset([ (el2.clientWidth / 2 + 20) + el1.clientWidth + 5, offset]);

            //                     //Need to dynamically handle overlap markers
            //                     // Skip coordinate - hardcode
            //                     switch (trail[0].name) {
            //                         case "Lake Stanley Draper Trail":
            //                             if(coord = "-97.38263547420502,35.365778574455774") {
            //                                 marker1.setOffset([el1.clientWidth / 2 + 10, 50]);
            //                                 marker2.setOffset([ (el2.clientWidth / 2 + 10) + el1.clientWidth + 5, 50]);
            //                             }
            //                             break;
            //                         case 'Katy Trail':
            //                             marker1.setOffset([el1.clientWidth / 2 + 10, -50]);
            //                             marker2.setOffset([ (el2.clientWidth / 2 + 10) + el1.clientWidth + 5, -50]);
            //                             break;
            //                         case 'Bricktown Canal Trail':
            //                             marker1.setOffset([el1.clientWidth / 2 + 10, 50]);
            //                             marker2.setOffset([ (el2.clientWidth / 2 + 10) + el1.clientWidth + 5, 50]);
            //                             break;
            //                         case 'Oklahoma River Trail North':
            //                             marker1.setOffset([el1.clientWidth / 2 + 10, -200]);
            //                             marker2.setOffset([ (el2.clientWidth / 2 + 10) + el1.clientWidth + 5, -200]);
            //                             break;
            //                         default:

            //                     }

            //                     let markerArray = onTrailMarkers[trail[0].name] = [marker1, marker2];
            //                     setOnTrailMarkers(markerArray);
            //                     coord_in_bounds = true;
            //                 }
            //             })

            //         })
            //     coord_in_bounds = false;
            // } else if(trail[0].geometry.type == "LineString"){
            //     trail[0].geometry.coordinates.forEach((coord) => {
            //         console.log('trail name LS', trail[0].name);
            //             if (inBounds(coord, bounds) && !coord_in_bounds) {
            //                 let latitude = coord[1];
            //                 let longitude = coord[0];
            //                 let onTrails = Object.keys(onTrailMarkers);
            //                 var markerHeight = 45;
            //                 let offset = 0;
            //                 for (let i = 0; i < onTrails.length; i++) {
            //                     if (trail[0].name == onTrails[i]) {
            //                         continue;
            //                     }
            //                     console.log('testing trail', trail[0].name);
            //                     console.log('trail: ',onTrails[i], 'coords: ',onTrailMarkers[onTrails[i]][0].getLngLat());
            //                     console.log('point: ', map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()));
            //                     console.log('current point: ', map.current.project([longitude, latitude]));
            //                     console.log('point:', map.current.project([longitude, latitude]).y, 'compare:',map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y);
            //                     let currentPoint = map.current.project([longitude, latitude]).y;
            //                     let testPoint = map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y;
            //                     if (currentPoint >= testPoint - markerHeight && currentPoint <= testPoint + markerHeight ) {
            //                         console.log('trail name', trail[0].name, 'overlaps',onTrails[i]);
            //                         if (currentPoint + (markerHeight / 2) >= testPoint) {
            //                             console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'top');
            //                             offset = markerHeight;
            //                         }
            //                         if (currentPoint - (markerHeight / 2) <= testPoint) {
            //                             console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'bottom');
            //                             offset = -1 * markerHeight;
            //                         }
            //                         if (currentPoint + (markerHeight / 2) >= testPoint && currentPoint - (markerHeight / 2) <= testPoint) {
            //                             console.log('trail name', trail[0].name, 'overlaps',onTrails[i], 'all');
            //                             offset = 2 * markerHeight;
            //                         }

            //                         // console.log('offset 45px');
            //                         // offset = markerHeight;
            //                     }

            //                 }
            //                 let marker1 = new mapboxgl.Marker(el1).setLngLat([longitude, latitude]);
            //                 marker1.addTo(map.current);
            //                 marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
            //                 marker1.getElement().addEventListener('click', () => {
            //                     openSideBar(trail[0])
            //                 });

            //                 let marker2 = new mapboxgl.Marker(el2).setLngLat([longitude, latitude]);
            //                 marker2.addTo(map.current);
            //                 marker2.setOffset([ (el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
            //                 marker2.getElement().addEventListener('click', () => {
            //                     openSideBar(trail[0])
            //                 });

            //                 let markerArray = onTrailMarkers[trail[0].name] = [marker1, marker2];
            //                 setOnTrailMarkers(markerArray);
            //                 coord_in_bounds = true;
            //             }
            //         })
            //     coord_in_bounds = false;
            // }

            function processTrail(trail, map_bounds, el1, el2, onTrailMarkers, setOnTrailMarkers, map) {
                let coord_in_bounds = false;
            
                function handleOverlap(trailName, marker1, marker2, offset) {
                    // if (!coord) {
                    //     return;
                    // }
                    switch (trailName) {
                        case "Lake Stanley Draper Trail":
                            // if (coord === "-97.38263547420502,35.365778574455774") {
                            //     marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
                            //     marker2.setOffset([(el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
                            // }
                            break;
                        case 'Katy Trail':
                            marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
                            marker2.setOffset([(el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
                            break;
                        case 'Bricktown Canal Trail':
                            marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
                            marker2.setOffset([(el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
                            break;
                        case 'Oklahoma River Trail North':
                            marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
                            marker2.setOffset([(el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
                            break;
                        default:
                            break;
                    }
                }
            
                function processCoordinates(coordinates, markerHeight) {
                    if (!coordinates || coordinates.length === 0) {
                        return;
                    }
                    coordinates.forEach((coord) => {
                        if (!coord) {
                            return;
                        }
                        // console.log(`trail name ${trail[0].geometry.type}`, trail[0].name);
                        if (inBounds(coord, map_bounds) && !coord_in_bounds) {
                            let latitude = coord[1];
                            let longitude = coord[0];
                            let onTrails = Object.keys(onTrailMarkers);
                            let offset = 0;
            
                            for (let i = 0; i < onTrails.length; i++) {
                                if (trail[0].name === onTrails[i]) {
                                    continue;
                                }
            
                                let currentPoint = map.current.project([longitude, latitude]).y;
                                let testPoint = map.current.project(onTrailMarkers[onTrails[i]][0].getLngLat()).y;
            
                                if (currentPoint >= testPoint - markerHeight && currentPoint <= testPoint + markerHeight) {
                                    // console.log(`trail name ${trail[0].geometry.type}`, trail[0].name, 'overlaps', onTrails[i]);
            
                                    if (currentPoint + (markerHeight / 2) >= testPoint) {
                                        // console.log(`trail name ${trail[0].geometry.type}`, trail[0].name, 'overlaps', onTrails[i], 'top');
                                        offset = markerHeight;
                                    }
                                    if (currentPoint - (markerHeight / 2) <= testPoint) {
                                        // console.log(`trail name ${trail[0].geometry.type}`, trail[0].name, 'overlaps', onTrails[i], 'bottom');
                                        offset = -1 * markerHeight;
                                    }
                                    if (currentPoint + (markerHeight / 2) >= testPoint && currentPoint - (markerHeight / 2) <= testPoint) {
                                        // console.log(`trail name ${trail[0].geometry.type}`, trail[0].name, 'overlaps', onTrails[i], 'all');
                                        offset = 2 * markerHeight;
                                    }
                                }
                            }
            
                            let marker1 = new mapboxgl.Marker(el1).setLngLat([longitude, latitude]);
                            marker1.addTo(map.current);
                            marker1.setOffset([el1.clientWidth / 2 + 10, offset]);
                            marker1.getElement().addEventListener('click', () => {
                                openSideBar(trail[0]);
                            });
            
                            let marker2 = new mapboxgl.Marker(el2).setLngLat([longitude, latitude]);
                            marker2.addTo(map.current);
                            marker2.setOffset([(el2.clientWidth / 2 + 10) + el1.clientWidth + 5, offset]);
                            marker2.getElement().addEventListener('click', () => {
                                openSideBar(trail[0]);
                            });
            
                            handleOverlap(trail[0].name, marker1, marker2, offset);
            
                            let markerArray = onTrailMarkers[trail[0].name] = [marker1, marker2];
                            setOnTrailMarkers(markerArray);
                            coord_in_bounds = true;
                        }
                    });
                    coord_in_bounds = false;
                }
            
                if (trail[0].geometry.type === "MultiLineString") {
                    trail[0].geometry.coordinates.forEach((coordinate) => {
                        coordinate.forEach((coord) => {
                            processCoordinates(coordinate, 35);
                        });
                    });
                } else if (trail[0].geometry.type === "LineString") {
                    processCoordinates(trail[0].geometry.coordinates, 45);
                }
            }

            processTrail(trail, map_bounds, el1, el2, onTrailMarkers, setOnTrailMarkers, map);


        })

        // console.log(onTrailMarkers);
        // console.log(onsite_trails.length+' onsite trails');

        forceUpdate();
        // console.log(offsiteTrailsArr);
        // console.log(features)
        // console.log(trailsArr.current)
        // console.log(offsite_trails);
    }

    const inBounds = (point, map_bounds) => {
        // console.log('point[0]', point[0], 'map_bounds._sw.lng', map_bounds._sw.lng, 'map_bounds._ne.lng', map_bounds._ne.lng, 'point[1]', point[1], 'map_bounds._sw.lat', map_bounds._sw.lat, 'map_bounds._ne.lat', map_bounds._ne.lat);
        var lng = (point[0] - map_bounds._ne.lng + .0002) * (point[0] - map_bounds._sw.lng - .0002) < 0;
        var lat = (point[1] - map_bounds._ne.lat + .002) * (point[1] - map_bounds._sw.lat - .002) < 0;
        return lng && lat;
    }

    const changeBackgroundOnHover = (e) => {
        e.target.style.background = '#2c973f';
        e.target.style.cursor = 'pointer';
    }

    const changeBackgroundOnMouseOut = (e) => {
        e.target.style.background = 'rgb(113, 115, 117)';
    }

    const createOffsiteTrailName = (trail, direction) => {

        const new_trail_name = (<div onMouseOut={changeBackgroundOnMouseOut} onMouseOver={changeBackgroundOnHover} onClick={() => {openSideBar(trail[0]); centerMap(trail[0].startPointMarker.lng, trail[0].startPointMarker.lat)}} className={'offsite-trail '+direction+'-trail'} key={trail[0]._id}>{trail[0].name} (Click)</div>);

        switch (direction) {
            case 'left':
                leftOffsiteTrailsArr.current.push(new_trail_name);
                break;
            case 'right':
                rightOffsiteTrailsArr.current.push(new_trail_name);
                break;
            case 'top':
                topOffsiteTrailsArr.current.push(new_trail_name);
                break;
            case 'bottom':
                bottomOffsiteTrailsArr.current.push(new_trail_name);
                break;
        }
    }

    const handleToggle = (direction) => {
        switch(direction){
           case "left":
               setShowOffSiteLeftTrail(!showOffSiteLeftTrail);
                break;
            case "right":
                setShowOffSiteRightTrail(!showOffSiteRightTrail);
                break;
            case "top":
                setShowOffSiteTopTrail(!showOffSiteTopTrail);
                break;
            case "bottom":
                setShowOffSiteBottomTrail(!showOffSiteBottomTrail);
                break;
            default:
        }
    };

    const renderOffsiteTrailNames = () => {
        // console.log('render me');
        return (
            <div className="offsite-trails">
                <div className="offsite-trails-left">
                    <div className= "offsite-trail left-trail"  onClick={() => handleToggle("left")}>Show / Hide Trails ({leftOffsiteTrailsArr.current.length}) </div>
                    <div className={showOffSiteLeftTrail ? "showOffSiteTrail" : "hideOffSiteTrail"} > {leftOffsiteTrailsArr.current} </div>
                </div>
                <div className="offsite-trails-right">
                    <div className="offsite-trail right-trail"  onClick={() => handleToggle("right")}>Show / Hide Trails ({rightOffsiteTrailsArr.current.length}) </div>
                    <div className={showOffSiteRightTrail ? "showOffSiteTrail" : "hideOffSiteTrail"} >{rightOffsiteTrailsArr.current}</div>
                </div>
                <div className="offsite-trails-top">
                    <div className={showOffSiteTopTrail ? "showOffSiteTrail" : "hideOffSiteTrail"} >{topOffsiteTrailsArr.current}</div>
                    <div className="offsite-trail top-trail"  onClick={() => handleToggle("top")}>Show / Hide Trails ({topOffsiteTrailsArr.current.length}) </div>
                </div>
                <div className="offsite-trails-bottom">
                    <div className="offsite-trail bottom-trail"  onClick={() => handleToggle("bottom")}>Show / Hide Trails ({bottomOffsiteTrailsArr.current.length}) </div>
                    <div className={showOffSiteBottomTrail ? "showOffSiteTrail" : "hideOffSiteTrail"} >{bottomOffsiteTrailsArr.current}</div>
                </div>
            </div>);
    }

    const getTrailDirection = (trail) => {
        const map_bounds = map.current.getBounds()
        const north_east_lng = map_bounds.getNorthEast().lng
        const north_east_lat = map_bounds.getNorthEast().lat
        const south_west_lng = map_bounds.getSouthWest().lng
        const south_west_lat = map_bounds.getSouthWest().lat
        let direction = 'top';
        let x_direction = 'left';
        let y_direction = 'top';
        let x_diff = 0;
        let y_diff = 0;

        if (trail[0].startPointMarker.lng < south_west_lng) {
            // trail is to the left of the map
            x_diff = south_west_lng - trail[0].startPointMarker.lng;
            x_direction = 'left';
        }
        if (trail[0].startPointMarker.lng > north_east_lng) {
            // trail is to the right of the map
            x_diff = trail[0].startPointMarker.lng - north_east_lng;
            x_direction = 'right';
        }
        if (trail[0].startPointMarker.lat < south_west_lat) {
            // trail is to the bottom of the map
            y_diff = south_west_lat - trail[0].startPointMarker.lat;
            y_direction = 'bottom';
        }
        if (trail[0].startPointMarker.lat > north_east_lat) {
            // trail is to the top of the map
            y_diff = trail[0].startPointMarker.lat - north_east_lat;
            y_direction = 'top';
        }
        if (x_diff > y_diff) {
            return x_direction;
        }

        return y_direction;
    }

    const updateMarkers = () => {
            const map_bounds = map.current.getBounds()

            let newMarkers = markers.current.map((marker, index) => {
                let lngLat = marker.getLngLat();
                let showMarker = true;
                if (map.current.getZoom() < 14 && marker.subType == 'normal') {
                    showMarker = false;
                }
                if (showMarker && lngLat.lng >= map_bounds.getSouthWest().lng &&
                    lngLat.lng <= map_bounds.getNorthEast().lng &&
                    lngLat.lat <= map_bounds.getNorthEast().lat &&
                    lngLat.lat >= map_bounds.getSouthWest().lat) {
                    // console.log('add marker')
                    if (!marker.visible) {
                        marker.visible = true;
                        marker.addTo(map.current);
                    }
                } else {
                    // console.log('remove marker');
                    marker.visible = false;
                    marker.remove()
                }
                return marker;

            })

            // console.log(newMarkers);

            filteredMarkers.current = newMarkers.filter((marker) => {
                // console.log(marker.visible)
                if (marker.visible) {
                    return marker
                }
            })

            // rearrangeMarkers();
            // console.log(filteredMarkers.current);
            markers.current = newMarkers;
    }

    const rearrangeMarkers = () => {
        // console.log('rearrangeMarkers');
        // console.log(filteredMarkers.current.length + ' markers in view')
        filteredMarkers.current.forEach((currMarker, index) => {
            let currMarkerEl = currMarker.getElement();
            if (currMarker.subType == 'featured') {
                let markerImage = currMarkerEl.getElementsByTagName('img')[0];
                // console.log(markerImage)
                let markerHeight = markerImage.offsetHeight;
                let markerWidth = markerImage.offsetWidth;
                let markerAspectRatio = markerWidth / markerHeight;
                // console.log(currMarker.name,markerHeight,markerWidth)
                if (markerWidth == 70 && markerHeight / markerWidth <= .5) {
                    let diff = markerHeight / markerWidth;
                    let newMarkerWidth = markerWidth * (1 + (1 - diff));
                    // console.log(newMarkerWidth);
                    markerImage.style.maxWidth = newMarkerWidth+'px';
                }
            }
            // console.log('currMaker', currMarker);
            filteredMarkers.current.forEach((testMarker, index) => {
                // console.log('testMarker',testMarker);
                let new_x = 0;
                let new_y = 0;
                let x_diff = 0;
                let y_diff = 0;
                let move_marker = false;
                let direction = '';
                let x_direction = "";
                let y_direction = "";

                if (currMarker.id != testMarker.id) {
                    // let currMarkerLngLat = currMarker.getLngLat()
                    let currMarkerLngLat = [currMarker.longitude, currMarker.latitude]
                    let currMarkerPoint = map.current.project(currMarkerLngLat)
                    let testMarkerEl = testMarker.getElement();
                    // let testMarkerLngLat = testMarker.getLngLat()
                    let testMarkerLngLat = [testMarker.longitude, testMarker.latitude]
                    let testMarkerPoint = map.current.project(testMarkerLngLat)
                    let currMarkerHeight = currMarkerEl.offsetHeight;
                    let currMarkerWidth = currMarkerEl.offsetWidth;
                    let testMarkerHeight = testMarkerEl.offsetHeight;
                    let testMarkerWidth = testMarkerEl.offsetWidth;
                    // console.log(map.current.project(currMarkerLngLat))
                    // if (currMarker.longitude)
                    let currMarkerLeft = currMarkerPoint.x - (currMarkerWidth / 2)
                    let currMarkerTop = currMarkerPoint.y - (currMarkerHeight / 2)
                    let currMarkerRight = currMarkerPoint.x + (currMarkerWidth / 2)
                    let currMarkerBottom = currMarkerPoint.y + (currMarkerHeight / 2)

                    let testMarkerLeft = testMarkerPoint.x - (testMarkerWidth / 2)
                    let testMarkerTop = testMarkerPoint.y - (testMarkerHeight / 2)
                    let testMarkerRight = testMarkerPoint.x + (testMarkerWidth / 2)
                    let testMarkerBottom = testMarkerPoint.y + (testMarkerHeight / 2)

                    new_x = currMarkerPoint.x;
                    new_y = currMarkerPoint.y;

                    if (((currMarkerBottom <= testMarkerBottom && currMarkerBottom >= testMarkerTop) || (currMarkerTop >= testMarkerTop && currMarkerTop <= testMarkerBottom)) && ((currMarkerLeft >= testMarkerLeft && currMarkerLeft <= testMarkerRight) || (currMarkerRight >= testMarkerLeft && currMarkerRight <= testMarkerRight))) {

                    // if ((currMarkerBottom <= testMarkerBottom || currMarkerTop >= testMarkerTop) && (currMarkerLeft <= testMarkerRight || currMarkerRight >= testMarkerLeft)) {
                        if (currMarkerBottom >= testMarkerTop && currMarkerBottom <= testMarkerPoint.y) {
                            // currMarker is overlapping on the top of the testMarker. Move up.
                            move_marker = true;
                            y_diff = currMarkerBottom - testMarkerPoint.y
                            y_direction = "up"
                            // console.log('overlap up')
                        }

                        if (currMarkerTop <= testMarkerBottom && currMarkerTop >= testMarkerPoint.y) {
                            // currMarker is overlapping on the bottom of the testMarker. Move down.
                            move_marker = true;
                            y_diff = testMarkerPoint.y - currMarkerTop
                            y_direction = "down"
                            // console.log('overlap down')
                        }

                        if (currMarkerLeft <= testMarkerRight && currMarkerLeft >= testMarkerPoint.x) {
                            // currMarker is overlapping on the right of the testMarker. Move right.
                            move_marker = true;
                            x_diff = currMarkerLeft - testMarkerPoint.x
                            x_direction = "right"
                            // console.log('overlap right')
                        }

                        if (currMarkerRight >= testMarkerLeft && currMarkerRight <= testMarkerPoint.x) {
                            // currMarker is overlapping on the left of the testMarker. Move left.
                            move_marker = true;
                            x_diff = testMarkerPoint.x - currMarkerRight
                            x_direction = "left"
                            // console.log('overlap left')
                        }
                    }

                    // console.log(currMarkerPoint,currMarkerEl.offsetHeight,currMarkerEl.offsetWidth);
                    // console.log(testMarkerPoint,testMarkerEl.offsetHeight,testMarkerEl.offsetWidth);

                    if (Math.abs(y_diff) < Math.abs(x_diff)) {
                        direction = x_direction
                    } else {
                        direction = y_direction
                    }
                    // console.log(move_marker)
                    if (move_marker) {
                        // console.log(x_diff,y_diff)
                        // console.log(x_direction,y_direction)
                        // console.log(currMarker.name+' moved')
                        // console.log(currMarkerPoint)
                        // console.log(direction)
                        // console.log(testMarker.name);
                        // console.log(testMarkerTop)
                        // console.log(testMarkerPoint)
                        // console.log(testMarkerWidth)
                        // console.log(currMarker)
                        // console.log(testMarker)
                        if (direction == "up") {
                            new_y = testMarkerTop - 20 - (currMarkerHeight / 2);
                        }
                        if (direction == "down") {
                            new_y = testMarkerBottom + 20 + (currMarkerHeight / 2);
                        }
                        if (direction == "left") {
                            new_x = testMarkerLeft - 10 - (currMarkerWidth / 2);
                        }
                        if (direction == "right") {
                            new_x = testMarkerRight + 10 + (currMarkerWidth / 2);
                        }
                        // console.log(new_x, new_y)
                        let new_long_lat = map.current.unproject([new_x, new_y]);
                        currMarker.setLngLat(new_long_lat)
                    }

                }
            })
        });
    }

    const updateLiveAssets = () => {

        const map_bounds = map.current.getBounds()

        let newLiveAssets = liveAssets.current.map((marker, index) => {
            let lngLat = marker.getLngLat();

            if (map.current.getZoom() > 14 &&
                lngLat.lng >= map_bounds.getSouthWest().lng &&
                lngLat.lng <= map_bounds.getNorthEast().lng &&
                lngLat.lat <= map_bounds.getNorthEast().lat &&
                lngLat.lat >= map_bounds.getSouthWest().lat) {
                // console.log('add marker')
                if (!marker.visible) {
                    marker.visible = true;
                    marker.addTo(map.current);
                }
            } else {
                // console.log('remove marker');
                marker.visible = false;
                marker.remove()
            }
            return marker;

        })

        // console.log(newLiveAssets);

        filteredLiveAssets.current = newLiveAssets.filter((marker) => {
            // console.log(marker.visible)
            if (marker.visible) {
                return marker
            }
        })
        liveAssets.current = newLiveAssets;
    }

    const openSideBar = (data) => {
        setIsOpen(true)
        setSideBarData(data)
    }

    const closeSideBar = () => {
        setIsOpen(false)
        setSideBarData('')
    }

    function ParseDMS(input) {
        const parts = input.split(/[^\d\w]+/)
        const lng = ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3])
        const lat = ConvertDMSToDD(parts[4], parts[5], parts[6], parts[7])
        return [lng, lat]
    }

    function ConvertDMSToDD(days, minutes, seconds, direction) {
        let dd = parseFloat(days) + parseFloat(minutes / 60) + parseFloat(seconds / (60 * 60))
        if (direction === 'S' || direction === 'W') {
            dd *= -1
        } // No need to do anything for N or E
        return dd
    }

    const getMenuDetails = async () => {
        return await axios
            .get(`${base_url}/user/menu_details`)
            .then((response) => {
                if (response.data.success) {
                    let icon, cats
                    if (response.data.data.menu_details.subsettings.length > 0) {
                        icon = response.data.data.menu_details.subsettings[0].destinationIcon
                    }
                    if (response.data.data.menu_details.assetCatgory.length > 0) {
                        cats = response.data.data.menu_details.assetCatgory
                    }
                    return {destinationIcon: icon, assetCategories: cats}
                }
            })
            .catch(function (error) {
                return error
            })
    }

    const getMapData = async () => {
        return await axios
            .get(`${base_url}/user/map_data`)
            .then((response) => {
                if (response.data.success) {
                    const destinations = response.data.data.map_data.destinations
                    const districts = response.data.data.map_data.districts
                    const liveassets = response.data.data.map_data.liveassets
                    const trails = response.data.data.map_data.trails
                    return {destinations: destinations, districts: districts, liveassets: liveassets, trails: trails}
                }
            })
            .catch(function (error) {
                return error
            })
    }
    let defaultZoom = 9
    const windowsize = window.innerWidth;
    if (windowsize < 600) {
        defaultZoom = 12
    }
    const generateMap = async () => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/chrisdecibelkc/cl8aft9lx002f15mh30af487w',
            // center: [longitude, latitude], //todo..  i dont think its required..GeolocateControl will handle it automatically
            zoom: defaultZoom,
            maxBounds: bounds,
            minZoom: 10,
            maxZoom: 14.99
        })

        map.current.addControl(
            new mapboxgl.NavigationControl({
                showZoom: true,
            }),
            'bottom-right'
        )

        /*
		map.current.addControl(
			new mapboxgl.NavigationControl({
				showCompass: true,
				showZoom: false
			}),
			'bottom-left'
		)
		*/

        map.current.on('movestart', () => {
            setShowOffSiteBottomTrail(false);
            setShowOffSiteLeftTrail(false);
            setShowOffSiteTopTrail(false);
            setShowOffSiteRightTrail(false);
        });

        const btnControls = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            showAccuracyCircle: true,
            showUserLocation: true,
            trackUserLocation: false,
            showUserHeading: true
        })
        map.current.addControl(btnControls)

        // btnControls.on('load', () => {
        // 	setTimeout(
        // 		() =>
        // 			document.querySelector('.mapboxgl-ctrl-compass').addEventListener('mousedown', () => {
        // 				openSideBar()
        // 			}),
        // 		1000
        // 	)
        // })

        map.current.on('load', () => {
            setTimeout(
                () =>
                    document.querySelector('.mapboxgl-ctrl-compass').addEventListener('mousedown', () => {
                        openSideBar()
                    }),
                1000
            )
            updateMarkers();
            updateLiveAssets();
        })

        map.current.on('moveend', () => {
            updateMarkers();
            updateLiveAssets();
            updateOffsiteTrails();
        })

        map.current.on('zoomstart', () => {
            oldZoom.current = map.current.getZoom();
            // console.log(oldZoom.current);
        })
        map.current.on('zoomend', () => {
            // We need to redraw the trail outlines as we zoom in, because the offset becomes too large at higher zoom levels.
            showTrailsOnMap(false).then(() => {
                generateTrailOutlines(false);
            });
        })

        map.current.on('moveend', () => {
            if (moving) {
                return;
            }
            const { lng, lat } = map.current.getCenter();
            const current_zoom = map.current.getZoom();
            // console.log('current_zoom', current_zoom);
            let minLng = bounds[0][0];
            let maxLng = bounds[1][0];
            let minLat = bounds[0][1];
            let maxLat = bounds[1][1];
            // console.log('center test');
            // // Check if the map center is out of bounds
            // console.log('lng', lng);
            // console.log('minLng', minLng);
            // console.log('maxLng', maxLng);
            
            // console.log('lat', lat);
            // console.log('minLat', minLat);
            // console.log('maxLat', maxLat);

            const viewable_bounds = map.current.getBounds();
            const viewable_height = viewable_bounds.getNorthEast().lat - viewable_bounds.getSouthWest().lat;
            const viewable_width = viewable_bounds.getNorthEast().lng - viewable_bounds.getSouthWest().lng;

            // if any of the viewable area is out of bounds, set the map center to the closest in-bound coordinates
            const min_viewable_lng = minLng + (viewable_width / 2);
            const max_viewable_lng = maxLng - (viewable_width / 2);
            const min_viewable_lat = minLat + (viewable_height / 2);
            const max_viewable_lat = maxLat - (viewable_height / 2);

            let newLng = lng;
            let newLat = lat;
            if (lng < min_viewable_lng) {
                newLng = minLng + (viewable_width / 2);
            }
            if (lng > max_viewable_lng) {
                newLng = maxLng - (viewable_width / 2);
            }
            if (lat < min_viewable_lat) {
                newLat = minLat + (viewable_height / 2);
            }
            if (lat > max_viewable_lat) {
                newLat = maxLat - (viewable_height / 2);
            }

            if (lng < min_viewable_lng || lng > max_viewable_lng || lat < min_viewable_lat || lat > max_viewable_lat) {
                // console.log('out of bounds', newLng, newLat, minLng, maxLng, minLat, maxLat);
            //     // Calculate the closest in-bound coordinates
            //     const clampedLng = Math.max(minLng, Math.min(maxLng, lng));
            //     const clampedLat = Math.max(minLat, Math.min(maxLat, lat));

                moving = true;
                
                setTimeout(() => {
                    map.current.setCenter([newLng, newLat]);
                    moving = false;
                }, 1000);
            }
        });

        map.current.on('click', (e) => {
            // console.log(`A click event has occurred at ${e.lngLat}`);
        });

        const menuDetails = await getMenuDetails(map)
        const mapData = await getMapData()


        if (mapData.destinations.length > 0) {
            for (const item of mapData.destinations) {
                if (!item.enabled) {
                    continue;
                }
                const img = createImgElement()
                const itemDiv = createParentDivElement()
                const textElement = createTextElement()
                textElement.innerHTML = `${item.name}`
                itemDiv.addEventListener('click', () => {
                    openSideBar(item)
                })
                itemDiv.style.zIndex = 2;
                if (!item.nameLocation) {
                    item.nameLocation = 'bottom';
                }

                if (!item.icon || item.icon === null) {

                    if(item.category =='museum')
                    {
                        img.src = `${CivicIcon}`
                    } else if (item.category == 'point_of_interest')
                    {
                        img.src = `${POIIcon}`
                    } else {
                        img.src = `${ParkRecIcon}`
                    }

                    itemDiv.classList.add('normal-destination')
                    img.style.maxWidth = '30px'
                    item.subType = 'normal'
                } else {
                    img.src = `${aws_url}/${item.icon}`
                    // img.style.maxWidth = '60px'
                    // img.style.maxHeight = '40px'
                    item.subType = 'featured'
                }

                if (item.nameLocation === 'bottom') {
                    itemDiv.classList.add('name-location-bottom');
                    itemDiv.appendChild(img);
                    itemDiv.appendChild(textElement).className = "showFeaturedName";
                } else {
                    itemDiv.classList.add('name-location-top');
                    itemDiv.appendChild(textElement).className = "showFeaturedName";
                    itemDiv.appendChild(img);
                }
                const marker = new mapboxgl.Marker(itemDiv);

                if ((item.longitude.includes('W') || item.longitude.includes('E')) && item.longitude.includes('.')) {
                    // handle format : 35.4676° N, 97.5164° W
                     marker.setLngLat([parseFloat(item.longitude) * -1, parseFloat(item.latitude)]).addTo(map.current)
                } else if (item.longitude.includes('W') || item.longitude.includes('E')) {
                    // handle format : DMS Lat: 35° 28' 54.9048'' N, DMS Long: 97° 30' 30.4884'' W
                    marker.setLngLat([ParseDMS(item.longitude.concat(item.latitude))]).addTo(map.current)
                } else {
                    // handle format: 35.49, -97.5
                    marker.setLngLat([item.longitude, item.latitude]).addTo(map.current)
                }
                if (markers && markers.current) {
                    marker.visible = true;
                    marker.type = 'destination';
                    marker.id = item._id;
                    marker.longitude = item.longitude
                    marker.latitude = item.latitude
                    marker.name = item.name
                    marker.subType = item.subType
                    markers.current.push(marker);
                }

                const zoomLevel = map.current.getZoom()

                if (zoomLevel >= 13) {
                    if (item.subType && item.subType == 'featured') {
                        itemDiv.classList.add('largerIcon')
                        itemDiv.classList.remove('largestIcon')
                        itemDiv.appendChild(textElement).className = "showFeaturedName";
                    }
                }
                if (zoomLevel >= 14) {
                    if (item.subType && item.subType == 'featured') {
                        itemDiv.classList.add('largestIcon')
                        itemDiv.classList.remove('largerIcon')
                        itemDiv.appendChild(textElement).className = "showFeaturedName";
                    }
                }
                if (zoomLevel < 14) {
                    if (item.subType && item.subType == 'featured') {

                    }
                }
                if (zoomLevel < 13) {
                    if (item.subType && item.subType == 'featured') {
                        itemDiv.classList.remove('largerIcon')
                        itemDiv.classList.remove('largestIcon')
                        itemDiv.appendChild(textElement).className = "hideFeaturedName";
                    }
                }

                // Normal Destinations
                if (zoomLevel >= 13) {
                    // console.log(item.icon);
                    if (item.subType && item.subType == 'normal') {
                        itemDiv.classList.add('showIcon')
                        itemDiv.classList.remove('hideIcon')
                    }
                } else if (zoomLevel < 13) {
                    if (item.subType && item.subType == 'normal') {
                        itemDiv.classList.add('hideIcon')
                        itemDiv.classList.remove('showIcon')
                    }
                }
                if (zoomLevel >= 14) {
                    if (item.subType && item.subType == 'normal') {
                        itemDiv.classList.add('showText')
                    }
                } else {
                    if (item.subType && item.subType == 'normal') {
                        itemDiv.classList.remove('showText')
                    }
                }

                map.current.on('zoom', () => {
                    const zoomLevel = map.current.getZoom()

                    if (zoomLevel >= 13) {
                        if (item.subType && item.subType == 'featured') {
                            itemDiv.classList.add('largerIcon')
                            itemDiv.classList.remove('largestIcon')
                            itemDiv.appendChild(textElement).className = "showFeaturedName";
                        }
                    }
                    if (zoomLevel >= 14) {
                        if (item.subType && item.subType == 'featured') {
                            itemDiv.classList.add('largestIcon')
                            itemDiv.classList.remove('largerIcon')
                            itemDiv.appendChild(textElement).className = "showFeaturedName";
                        }
                    }
                    if (zoomLevel < 14) {
                        if (item.subType && item.subType == 'featured') {

                        }
                    }
                    if (zoomLevel < 13) {
                        if (item.subType && item.subType == 'featured') {
                            itemDiv.classList.remove('largerIcon')
                            itemDiv.classList.remove('largestIcon')
                            itemDiv.appendChild(textElement).className = "hideFeaturedName";
                        }
                    }

                    // Normal Destinations
                    if (zoomLevel >= 13) {
                        // console.log(item.icon);
                        if (item.subType && item.subType == 'normal') {
                            itemDiv.classList.add('showIcon')
                            itemDiv.classList.remove('hideIcon')
                        }
                    } else if (zoomLevel < 13) {
                        if (item.subType && item.subType == 'normal') {
                            itemDiv.classList.add('hideIcon')
                            itemDiv.classList.remove('showIcon')
                        }
                    }
                    if (zoomLevel >= 14) {
                        if (item.subType && item.subType == 'normal') {
                            itemDiv.classList.add('showText')
                        }
                    } else {
                        if (item.subType && item.subType == 'normal') {
                            itemDiv.classList.remove('showText')
                        }
                    }
                })
            }
        }

        if (mapData.districts.length > 0) {
            for (const item of mapData.districts) {
                if (!item.enabled) {
                    continue;
                }

                const itemDiv = createParentDivElement()
                const textElement = createTextElement()
                textElement.innerHTML = `${item.name}`
                itemDiv.addEventListener('click', () => {
                    openSideBar(item)
                })
                itemDiv.appendChild(textElement)


                // textElement.innerHTML = `${item.name}`
                const el = document.createElement('div');
                itemDiv.className = 'marker';
                itemDiv.textContent = `${item.name}`
                itemDiv.style.color = 'white'
                itemDiv.style.fontSize = '2em'
                itemDiv.style.textShadow = '2px 2px 2px #555'
                itemDiv.style.fontFamily = 'Gotham Light'
                itemDiv.style.textTransform = 'capitalize'
                itemDiv.style.lineHeight = '1em'

                let marker = new mapboxgl.Marker(itemDiv);

                if ((item.longitude.includes('W') || item.longitude.includes('E')) && item.longitude.includes('.')) {
                    // handle format : 35.4676° N, 97.5164° W
                    marker.setLngLat([parseFloat(item.longitude) * -1, parseFloat(item.latitude)]).addTo(map.current);
                } else if (item.longitude.includes('W') || item.longitude.includes('E')) {
                    // handle format : DMS Lat: 35° 28' 54.9048'' N, DMS Long: 97° 30' 30.4884'' W
                    marker.setLngLat([ParseDMS(item.longitude.concat(item.latitude))]).addTo(map.current);
                } else {
                    // handle format: 35.49, -97.5
                    marker.setLngLat([item.longitude, item.latitude]).addTo(map.current);
                }

                // if (map && map.current) {
                //     marker.type = 'district';
                //     map.current.push(marker);
                // }

                map.current.on('zoom', () => {
                    const zoomLevel = map.current.getZoom()
                    if (zoomLevel >= 14) {
                        itemDiv.classList.add('hideIcon')
                        itemDiv.classList.remove('showIcon')
                    } else if (zoomLevel < 13) {
                        itemDiv.classList.add('hideIcon')
                        itemDiv.classList.remove('showIcon')
                    } else {
                        itemDiv.classList.add('showIcon')
                        itemDiv.classList.remove('hideIcon')
                    }
                })
            }
        }

        if (mapData.liveassets.length > 0) {
            for (const item of mapData.liveassets) {
                if (!item.enabled) {
                    continue;
                }
                const itemDiv = createParentDivElement()
                const textElement = createTextElement()
                textElement.innerHTML = `${item.name}`
                itemDiv.addEventListener('click', () => {
                    openSideBar(item)
                })
                // itemDiv.appendChild(textElement)
                if (menuDetails.assetCategories.length > 0) {
                    for (const category of menuDetails.assetCategories) {
                        if (item.type === category.name) {
                            itemDiv.style.backgroundImage = `url(${aws_url}/${category.icon})`
                        }
                    }
                }
                itemDiv.classList.add('live-asset');
                itemDiv.style.backgroundSize = 'contain'
                itemDiv.style.backgroundRepeat = 'no-repeat'
                itemDiv.style.backgroundPosition = 'center center'
                itemDiv.style.width = '1.5rem'
                itemDiv.style.height = '2.25rem'
                itemDiv.style.top = '-1.5rem'
                const marker = new mapboxgl.Marker(itemDiv);

                if ((item.longitude.includes('W') || item.longitude.includes('E')) && item.longitude.includes('.')) {
                   marker.setLngLat([parseFloat(item.longitude) * -1, parseFloat(item.latitude)]);
                } else if (item.longitude.includes('W') || item.longitude.includes('E')) {
                   marker.setLngLat([ParseDMS(item.longitude.concat(item.latitude))]);
                } else {
                   marker.setLngLat([item.longitude, item.latitude]);
                }

                if (liveAssets && liveAssets.current) {
                    marker.visible = true;
                    marker.type = 'live-asset';
                    liveAssets.current.push(marker);
                }

            }
        }

        if (mapData.trails.length > 0) {
            loadTrails(mapData.trails)
        }

        showStreetCarRoutes();
        showBikeRoutes();
        showTransitRoutes();
        
        


        if (window.innerWidth < 1440) {
            // map.current.zoomTo(7, {duration: 9000})
        }

        // map.current.on('move', () => {
        // 	map.current.zoomTo(10, {duration: 9000})
        // })

        // loading spinner
        const spinnerEl = document.getElementById('spinner')
        const backgroundEl = document.getElementById('loading-background')

        map.current.on('load', () => {
            loadingSpinner(false)
        })

        function loadingSpinner(on) {
            if (on) {
                spinnerEl.classList.add('loading')
                backgroundEl.classList.add('absolute')
                backgroundEl.classList.remove('none')
            } else {
                spinnerEl.classList.remove('loading')
                backgroundEl.classList.remove('absolute')
                backgroundEl.classList.add('none')
            }
        }
        ////////////////////////////////////// loading spinner

        getLocation();

    }

    const setUserPosition = (position) => {
        const el = document.createElement('div');
        const width = 140;
        const height = 120;
        el.className = 'marker';
        el.style.backgroundImage = `url(${YouAreHere})`;
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.backgroundSize = '100%';

        // Create a default Marker and add it to the map.

        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        const marker1 = new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude]);


        marker1.addTo(map.current);

        setUserCurrentPosA(marker1);


    }


    const getLocation = () => {
        if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
        } else {
            // console.log('Locating...');

            navigator.geolocation.getCurrentPosition((position) => {
                let localPos = navigator.geolocation.watchPosition(setUserPosition);
                setPositionWatcher(localPos);

            }, () => {
                console.log('Unable to retrieve your location');
            },
                {timeout:10000});
        }
    }

    const generateTrailOutlines = (force) => {
        const zoomLevel = map.current.getZoom();
        
        if (oldZoom.current < 13 && zoomLevel < 13 && !force) {
            return;
        }
        if (oldZoom.current -.05 >= 13 && oldZoom.current + .05 < 14 && zoomLevel >= 13 && zoomLevel < 14 && !force) {
            return;
        }
        if (oldZoom.current >= 14 && zoomLevel >= 14 && !force) {
            return;
        }
        
        trailIds.current.forEach((trailId) => {
            if (map.current.getLayer("outline-"+trailId)) {
                map.current.removeLayer("outline-"+trailId);
            }
            if (map.current.getSource("outline-"+trailId)) {
                map.current.removeSource("outline-"+trailId);
            }
        })

        let longAdjustment = .0004;
        let latAdjustment = .0004;
        if (zoomLevel >= 13) {
            longAdjustment = .0003;
            latAdjustment = .0001;
        }
        if (zoomLevel >= 14) {
            longAdjustment = .00008;
            latAdjustment = .00008;
        }

        trailsArr.current.forEach((trail) => {
            let item = trail[0];
            let data = trail[1];
            for (let i = 0; i < data.features.length; i++) {
                let trail_id = item._id+'-'+data.features[i].id;
                let coords = data.features[i].geometry.coordinates;
                let type = data.features[i].geometry.type;
                let shadow_coords = coords.map((coord_array) => {
                    if (type === 'LineString') {
                        let newCoord = [coord_array[0]+longAdjustment, coord_array[1]-latAdjustment];
                        return newCoord;
                    } else {
                        return coord_array.map((coord) => {
                            let newCoord = [coord[0]+longAdjustment, coord[1]-latAdjustment];
                            return newCoord;
                        })
                    }
                })
                
                map.current.addSource(`outline-${trail_id}`, {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: type,
                            coordinates: shadow_coords
                        }
                    }
                })

                map.current.addLayer({
                    id: `outline-${trail_id}`,
                    type: 'line',
                    source: `outline-${trail_id}`,
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        'line-color': '#477315',
                        'line-width': 12
                    }
                },'route-'+trail_id)
            }
        })

    }

    const showBikeRoutes = () => {

        let bikeLanes = [`${BikeLane}`, `${ProtectedBikeLane}`, `${PaveShoulderBikeRoute}`];

        for (const item of bikeLanes) {
            let json = fetchJsonData(item).then((response) => {
                return response
            })
            // console.log(JSON.stringify(json))
            json.then(function (result) {
                // console.log(JSON.stringify(result))
                if (result !== undefined) {
                    const data = result.data
                    let lineCoords = [],
                        multiLineCoords = [],
                        center = ''
                    let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
                    let utm = 'PROJCS["NAD83 / Oklahoma",GEOGCS["CRS84",DATUM["CRS84_National_Spatial_Reference_System_2007", SPHEROID["GRS 1980",6378137,298.257222101, AUTHORITY["EPSG","7019"]], TOWGS84[0,0,0,0,0,0,0], AUTHORITY["EPSG","6759"]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4759"]], PROJECTION["Lambert_Conformal_Conic_2SP"], PARAMETER["standard_parallel_1",35.57], PARAMETER["standard_parallel_2",46], PARAMETER["latitude_of_origin",35.01], PARAMETER["central_meridian",-114.0], PARAMETER["false_easting",600000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AXIS["X",EAST], AXIS["Y",NORTH], AUTHORITY["EPSG","3639"]]'
                    if (data) {

                        bikesArr.current.push([item,data]);

                        let coords = {}

                        for (let i = 0; i < data.features.length; i++) {
                            let bike_id = item._id+'-'+data.features[i].id+generateRandomNumber(20000);
                            bikeIds.current.push(bike_id);

                            coords = data.features[i].geometry.coordinates;
                            let type = data.features[i].geometry.type;

                            map.current.addSource(`bike-${bike_id}`, {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: type,
                                        coordinates: coords
                                    }
                                }
                            })

                            map.current.addLayer({
                                id: `bike-${bike_id}`,
                                type: 'line',
                                source: `bike-${bike_id}`,
                                layout: {
                                    'line-join': 'round',
                                    'line-cap': 'round'
                                },
                                minzoom: 14,
                                maxzoom: 20,
                                paint: {
                                    // 'line-color': '#888',
                                    'line-color': '#F68A32',
                                    'line-width': 6
                                }
                            }, 'streetcar-'+streetCarIds.current[0])
                        }

                        // turning off spinner
                        const spinnerEl = document.getElementById('spinner')
                        const backgroundEl = document.getElementById('loading-background')

                        spinnerEl.classList.remove('loading')
                        backgroundEl.classList.remove('absolute')
                        backgroundEl.classList.add('none')

                        /*
                            }
                        }
                        */
                    }
                }
            })

        }

    }

    const showTransitRoutes = () => {

        let transitArr = [`${EmbarkTransitRoute}`];

        for (const item of transitArr) {
            let json = fetchJsonData(item).then((response) => {
                return response
            })
            // console.log(JSON.stringify(json))
            json.then(function (result) {
                // console.log(JSON.stringify(result))
                if (result !== undefined) {
                    let data = result.data
                    // console.log(data);
                    let lineCoords = [],
                        multiLineCoords = [],
                        center = ''
                    let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
                    let utm = 'PROJCS["NAD83 / Oklahoma",GEOGCS["CRS84",DATUM["CRS84_National_Spatial_Reference_System_2007", SPHEROID["GRS 1980",6378137,298.257222101, AUTHORITY["EPSG","7019"]], TOWGS84[0,0,0,0,0,0,0], AUTHORITY["EPSG","6759"]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4759"]], PROJECTION["Lambert_Conformal_Conic_2SP"], PARAMETER["standard_parallel_1",35.57], PARAMETER["standard_parallel_2",46], PARAMETER["latitude_of_origin",35.01], PARAMETER["central_meridian",-114.0], PARAMETER["false_easting",600000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AXIS["X",EAST], AXIS["Y",NORTH], AUTHORITY["EPSG","3639"]]'
                    if (data) {
                        transitsArr.current.push([item,data]);

                        let coords = {}

                        for (let i = 0; i < data.features.length; i++) {

                            let transit_id = item._id+'-'+data.features[i].id+generateRandomNumber(20000);
                            transitIds.current.push(transit_id);


                            coords = data.features[i].geometry.coordinates;
                            let type = data.features[i].geometry.type;

                            map.current.addSource(`route-${transit_id}`, {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: type,
                                        coordinates: coords
                                    }
                                }
                            })

                            map.current.addLayer({
                                id: `route-${transit_id}`,
                                type: 'line',
                                source: `route-${transit_id}`,
                                layout: {
                                    'line-join': 'round',
                                    'line-cap': 'round'
                                },
                                minzoom: 14,
                                maxzoom: 20,
                                paint: {
                                    // 'line-color': '#888',
                                    'line-color': '#F285B5',
                                    'line-width': 6
                                }
                            }, 'bike-'+bikeIds.current[0])
                        }

                        // })

                        // turning off spinner
                        const spinnerEl = document.getElementById('spinner')
                        const backgroundEl = document.getElementById('loading-background')

                        spinnerEl.classList.remove('loading')
                        backgroundEl.classList.remove('absolute')
                        backgroundEl.classList.add('none')

                        /*
                            }
                        }
                        */
                    }
                }
            })

        }

    }

    const showStreetCarRoutes = () => {

        let routeArr = [`${StreetCarRoutes}`];

        for (const item of routeArr) {
            let json = fetchJsonData(item).then((response) => {
                return response
            })
            // console.log(JSON.stringify(json))
            json.then(function (result) {
                // console.log(JSON.stringify(result))
                if (result !== undefined) {
                    let data = result.data
                    // console.log(data);
                    let lineCoords = [],
                        multiLineCoords = [],
                        center = ''
                    let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
                    let utm = 'PROJCS["NAD83 / Oklahoma",GEOGCS["CRS84",DATUM["CRS84_National_Spatial_Reference_System_2007", SPHEROID["GRS 1980",6378137,298.257222101, AUTHORITY["EPSG","7019"]], TOWGS84[0,0,0,0,0,0,0], AUTHORITY["EPSG","6759"]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4759"]], PROJECTION["Lambert_Conformal_Conic_2SP"], PARAMETER["standard_parallel_1",35.57], PARAMETER["standard_parallel_2",46], PARAMETER["latitude_of_origin",35.01], PARAMETER["central_meridian",-114.0], PARAMETER["false_easting",600000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AXIS["X",EAST], AXIS["Y",NORTH], AUTHORITY["EPSG","3639"]]'
                    if (data) {
                        streetCarArr.current.push([item,data]);

                        let coords = {}

                        for (let i = 0; i < data.features.length; i++) {

                            let streetcar_id = item._id+'-'+data.features[i].id+generateRandomNumber(20000);
                            streetCarIds.current.push(streetcar_id);


                            coords = data.features[i].geometry.coordinates;
                            let type = data.features[i].geometry.type;

                            map.current.addSource(`streetcar-${streetcar_id}`, {
                                type: 'geojson',
                                data: {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: type,
                                        coordinates: coords
                                    }
                                }
                            })

                            map.current.addLayer({
                                id: `streetcar-${streetcar_id}`,
                                type: 'line',
                                source: `streetcar-${streetcar_id}`,
                                layout: {
                                    'line-join': 'round',
                                    'line-cap': 'round'
                                },
                                minzoom: 14,
                                maxzoom: 20,
                                paint: {
                                    // 'line-color': '#888',
                                    'line-color': '#e337ff',
                                    'line-width': 6
                                }
                            }, "road-intersection")
                        }

                        // })

                        // turning off spinner
                        const spinnerEl = document.getElementById('spinner')
                        const backgroundEl = document.getElementById('loading-background')

                        spinnerEl.classList.remove('loading')
                        backgroundEl.classList.remove('absolute')
                        backgroundEl.classList.add('none')

                        /*
                            }
                        }
                        */
                    }
                }
            })

        }

    }



    const showTrailsOnMap = async (force) => {
        if (!map.current) {
            return;
        }
        const zoomLevel = map.current.getZoom();
        // console.log('zoomLevel', zoomLevel, 'oldZoom', oldZoom.current, 'force', force);

        if (oldZoom.current < 13 && zoomLevel < 13 && !force) {
            return;
        }
        if (oldZoom.current -.05 >= 13 && oldZoom.current + .05 < 14 && zoomLevel >= 13 && zoomLevel < 14 && !force) {
            return;
        }
        if (oldZoom.current >= 14 && zoomLevel >= 14 && !force) {
            return;
        }

        let behindLayer = null;
        if (zoomLevel >= 14) {
            behindLayer = 'road-intersection';
        }

        trailIds.current.forEach((trailId) => {
            if (map.current.getLayer("route-"+trailId)) {
                map.current.removeLayer("route-"+trailId);
            }
            if (map.current.getSource("route-"+trailId)) {
                map.current.removeSource("route-"+trailId);
            }
        })
        const trails = trailsArr.current;
        for (const trail of trails) {
            let item = trail[0];
            let data = trail[1];

            let coords = {}

            for (let i = 0; i < data.features.length; i++) {
                let trail_id = item._id+'-'+data.features[i].id;
                trailIds.current.push(trail_id);

                coords = data.features[i].geometry.coordinates;
                let type = data.features[i].geometry.type;

                map.current.addSource(`route-${trail_id}`, {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: type,
                            coordinates: coords
                        }
                    }
                })

                map.current.addLayer({
                    id: `route-${trail_id}`,
                    type: 'line',
                    source: `route-${trail_id}`,
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    paint: {
                        // 'line-color': '#888',
                        'line-color': '#91BD55',
                        'line-width': 12
                    }
                }, behindLayer)
            }

            coords = data.features[0].geometry.coordinates

            const startPointMarker = item.startPointMarker ?? null;
            const stopPointMarker = item.stopPointMarker ?? null;
            const startPoint = coords[0][0]
            const endPoint = coords[0][coords.length - 1]

            let nameLatLng = [],
                side = ''

                if (startPoint[0] < imageCoordinates_lvl1[0][0][0]) {
                    side = 'left'
                    const diff = generateRandomNumber('small')
                    nameLatLng[0] = imageCoordinates_lvl1[0][0][0] + diff
                } else if (startPoint[0] > imageCoordinates_lvl1[0][1][0]) {
                    side = 'right'
                    const diff = generateRandomNumber()
                    nameLatLng[0] = imageCoordinates_lvl1[0][1][0] - diff // dont change
                } else {
                    nameLatLng[0] = startPoint[0]
                }

                if (startPoint[1] > imageCoordinates_lvl1[0][0][1]) {
                    side = 'top'
                    const diff = generateRandomNumber('small')
                    nameLatLng[1] = imageCoordinates_lvl1[0][0][1] - diff
                } else if (startPoint[1] < imageCoordinates_lvl1[0][2][1]) {
                    side = 'bottom'
                    const diff = generateRandomNumber()
                    nameLatLng[1] = imageCoordinates_lvl1[0][2][1] + diff + 0.01 // dont change
                } else {
                    nameLatLng[1] = startPoint[1] - 0.005
                }

            map.current.on('load', () => {
                // const currentCoords = [map.current.getBounds()._sw, map.current.getBounds()._ne]
                nameLatLng = responsiveHandler(startPoint, [map.current.getBounds()._sw, map.current.getBounds()._ne])
            })

            map.current.on('move', () => {
                nameLatLng = responsiveHandler(startPoint, [map.current.getBounds()._sw, map.current.getBounds()._ne])
            })

            const nameElement = createNameElement(item.name, side)
            nameElement.addEventListener('click', () => {
                openSideBar(item)
            })

            if (startPointMarker) {
                new mapboxgl.Marker(createPoint('start')).setLngLat(startPointMarker).addTo(map.current)
            }
            if (stopPointMarker) {
                new mapboxgl.Marker(createPoint('end')).setLngLat(stopPointMarker).addTo(map.current)
            }
            // new mapboxgl.Marker(nameElement).setLngLat(nameLatLng).addTo(map.current)


            // turning off spinner
            const spinnerEl = document.getElementById('spinner')
            const backgroundEl = document.getElementById('loading-background')

            spinnerEl.classList.remove('loading')
            backgroundEl.classList.remove('absolute')
            backgroundEl.classList.add('none')
        }
        return;
    }
   
    const loadTrails = (trails) => {
        // console.log('trails', trails);
        for (const item of trails) {
            if(item.enabled == true) {
                if (item.jsonFile !== null) {
                    let json = fetchJsonData(`${aws_url}/${item.jsonFile}`).then((response) => {
                        return response
                    })
                    json.then(function (result) {
                        if (result !== undefined) {
                            let data = result.data

                            let lineCoords = [],
                                multiLineCoords = [],
                                center = ''
                            let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
                            let utm = 'PROJCS["NAD83 / Oklahoma",GEOGCS["CRS84",DATUM["CRS84_National_Spatial_Reference_System_2007", SPHEROID["GRS 1980",6378137,298.257222101, AUTHORITY["EPSG","7019"]], TOWGS84[0,0,0,0,0,0,0], AUTHORITY["EPSG","6759"]], PRIMEM["Greenwich",0, AUTHORITY["EPSG","8901"]], UNIT["degree",0.0174532925199433, AUTHORITY["EPSG","9122"]], AUTHORITY["EPSG","4759"]], PROJECTION["Lambert_Conformal_Conic_2SP"], PARAMETER["standard_parallel_1",35.57], PARAMETER["standard_parallel_2",46], PARAMETER["latitude_of_origin",35.01], PARAMETER["central_meridian",-114.0], PARAMETER["false_easting",600000], PARAMETER["false_northing",0], UNIT["metre",1, AUTHORITY["EPSG","9001"]], AXIS["X",EAST], AXIS["Y",NORTH], AUTHORITY["EPSG","3639"]]'
                            if (data) {
                                trailsArr.current.push([item,data]);
                            }
                        }
                    })
                }
            }
        }
        setTriggerMaps(true);
    }

    const fetchJsonData = async (url) => {
        if (url.length > 45) {
            return await axios
                .get(url)
                .then((response) => {
                    return response
                })
                .catch(function (error) {
                    return error
                })
        }
    }

    const createImgElement = () => {
        const img = document.createElement('img')
        return img
    }

    const createParentDivElement = () => {
        const width = 6
        const height = 6
        const itemDiv = document.createElement('div')
        itemDiv.style.padding = 0
        itemDiv.style.position = 'absolute'
        itemDiv.style.border = 'none'
        itemDiv.style.borderRadius = '0'
        itemDiv.style.cursor = 'pointer'
        itemDiv.style.color = 'white'
        itemDiv.style.backgroundSize = '100%'
        itemDiv.style.backgroundColor = 'transparent'
        itemDiv.style.textAlign = 'center'
        itemDiv.style.display = 'block'
        itemDiv.style.zIndex = '1'
        return itemDiv
    }

    const createTextElement = () => {
        const textEl = document.createElement('div')
        // textEl.style.top = '5rem'
        textEl.style.fontFamily = 'Myriad Pro Bold'
        textEl.style.width = '100%'
        textEl.style.fontSize = '0.9rem'
        textEl.style.marginTop = '.5rem'
        textEl.style.fontWeight = 'bold'
        textEl.style.lineHeight = '1.25rem'
        textEl.style.textShadow = '2px 2px 2px #555'

        return textEl
    }

    const createPoint = (point) => {
        if (point === 'start') {
            const el = document.createElement('div')
            el.className = 'marker'
            el.style.backgroundColor = '#fff'
            el.style.height = '19px'
            el.style.width = '19px'
            el.style.borderRadius = '50%'
            el.style.border = '2px solid #659b1e'
            el.style.zIndex = '2'
            return el
        } else if (point === 'end') {
            const el = document.createElement('div')
            el.className = 'marker'
            el.style.backgroundColor = '#fff'
            el.style.height = '19px'
            el.style.width = '19px'
            el.style.borderRadius = '50%'
            el.style.border = '2px solid #659b1e'
            const innerDot = document.createElement('div')
            innerDot.style.backgroundColor = '#ff0000'
            innerDot.style.height = '11px'
            innerDot.style.width = '11px'
            innerDot.style.borderRadius = '50%'
            innerDot.style.position = 'absolute'
            innerDot.style.top = '2px'
            innerDot.style.left = '2px'
            el.appendChild(innerDot)
            return el
        }
    }

    const createNameElement = (text, side) => {
        const textEl = document.createElement('div')
        textEl.style.margin = '0.2rem'
        textEl.style.borderRadius = '2px'
        textEl.style.position = 'absolute'
        textEl.style.padding = '0 4px'
        textEl.style.textAlign = 'center'
        textEl.style.backgroundColor = '#91BD55'
        textEl.style.color = '#fff'
        textEl.style.cursor = 'pointer'
        const textLabel = document.createElement('span')
        textLabel.style.fontFamily = 'Klinic Slab Bold'
        textLabel.innerHTML = text
        textLabel.style.fontSize = '1rem'
        let arrowEl = ''
        if ((side !== undefined) & (side.length > 0)) {
            arrowEl = document.createElement('img')
            arrowEl.style.width = '1rem'
            arrowEl.style.height = '1rem'
            arrowEl.style.margin = 0
            arrowEl.style.padding = 0
            arrowEl.style.marginLeft = '0.4rem'
            if (side === 'left') {
                arrowEl.src = LeftArrow
            } else if (side === 'right') {
                arrowEl.src = RightArrow
            } else if (side === 'top') {
                arrowEl.src = UpArrow
            } else if (side === 'bottom') {
                arrowEl.src = DownArrow
            }
        }
        textEl.appendChild(textLabel)
        textEl.append(arrowEl)
        return textEl
    }

    function generateRandomNumber(small) {
        if (small === 'small') {
            let min = 0.012,
                max = 0.018,
                r = Math.random() * (max - min) + min
            return r
        } else {
            let min = 0.1,
                max = 0.15,
                r = Math.random() * (max - min) + min
            return r
        }
    }

    const responsiveHandler = (startPoint, currentCoords) => {
        let nameLatLng = []
        if (startPoint[0] < currentCoords[0].lng) {
            // side = 'left'
            const diff = generateRandomNumber('small')
            nameLatLng[0] = currentCoords[0].lng + diff + 0.1
        } else if (startPoint[0] > currentCoords[1].lng) {
            // side = 'right'
            const diff = generateRandomNumber()
            nameLatLng[0] = currentCoords[1].lng - diff - 0.1
        } else {
            nameLatLng[0] = startPoint[0]
        }
        if (startPoint[1] > currentCoords[1].lat) {
            // side = 'top'
            const diff = generateRandomNumber('small')
            nameLatLng[1] = imageCoordinates_lvl1[1].lat - diff - 0.1
        } else if (startPoint[1] < imageCoordinates_lvl1[0].lat) {
            // side = 'bottom'
            const diff = generateRandomNumber()
            nameLatLng[1] = imageCoordinates_lvl1[0].lat + diff + 0.1
        } else {
            nameLatLng[1] = startPoint[1]
        }
        return nameLatLng
    }

    return (
        <>
            <Map ref={mapContainer} id="mapContainer" style={{height: '100% !important'}}></Map>
            <pre id="info"></pre>
            <div id="loading-background" className="loader">
                <StyledSpinner id="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="12" fill="none" strokeWidth="3" />
                </StyledSpinner>
            </div>
            {renderOffsiteTrailNames()}
            {isOpen && <MapSideBar closeCB={closeSideBar} sideBarData={sideBarData} setSelected={setSearchSelected} />}
            <div id="northArrow"><img src={NorthArrowIcon} /></div>
        </>
    )
}

const Map = styled.div`
    // height: 100%;
    width: 100%;
    backgroundcolor: #fff;
    position: absolute;
    top: 4.1rem;
    bottom: 0;
    // overflow: unset;
    box-sizing: border-box;
    @media screen and (max-width: 500px) {
        top: 3.7rem;
    }
`

const StyledSpinner = styled.svg`
    animation: rotate 1s linear infinite;
    position: absolute;
    left: 46%;
    top: 45%;
    width: 100px;
    height: 100px;

    & .path {
        stroke: #5652bf;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`
export default MapContainer
