import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {base_url} from '../../config/config'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import OkcParks from '../../assets/icons/OKC_parks.png'
import OkcFlag from '../../assets/icons/OKC_seal.png'


const Header = () => {
	const [title1, setTitle1] = useState('OKC Trails')
	const [title2, setTitle2] = useState('')

	useEffect(() => {
		getSiteTitle()
	}, [])

	const getSiteTitle = async () => {
		await axios
			.get(`${base_url}/user/menu_details`)
			.then((response) => {
				if (response.data.success) {

					var siteTitle1 = '';
					if(response.data.data.menu_details.subsettings.length > 0) {
						siteTitle1  = response.data.data.menu_details.subsettings[0].name;
					}
					setTitle1(siteTitle1);
				}
			})
			.catch(function (error) {
				return error
			})
	}

	return (
		<Wrapper>
			<CustomRow>
				<Heading xs={12} sm={12} md={12} lg={12}>
					<Flex>
						<Title1>{title1}</Title1>
					</Flex>
					<NavList>
						<Link href="https://www.okc.gov">
							<Box1 src={OkcFlag} />
						</Link>
						<Link href="https://www.okc.gov/departments/parks-recreation">
							<Box2 src={OkcParks} />
						</Link>
					</NavList>
				</Heading>
			</CustomRow>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	// background: #659b1e;
	background: #2c973f;
	max-width: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	position: fixed;
	z-index: 4;
`

export const Title1 = styled.h1`
	font-family: "Gotham Bold";
	font-size: 1.4em;
	font-weight: bold;
	padding: 0;
	margin: 0;
`

export const Title2 = styled.h2`
	font-family: "Gotham Light";
	font-size: 1.4em;
	padding: 0;
	margin: 0 0 0 5px ;
`

const CustomRow = styled(Row)`
	margin-left: 0px;
	margin-right: 0px;
`

const Flex = styled(Col)`
	display: flex;
	align-items: center;
	width: 50%;
`

const Heading = styled(Col)`
	width: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
`

const NavList = styled.div`
	display: flex;
`

const Box1 = styled.img`
	margin-right: 0rem;
	width: 90px;
	height: 66px;
`

const Box2 = styled(Box1)`
	width: 80px;
`
const Link = styled.a`
	width: 100%;
	
`
export default Header
