/**
 * OKC - WebApp
 * Copyright © 2022 OKC. All rights reserved.
 */
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Col, Container, Row} from 'styled-bootstrap-grid'
import axios from 'axios'
import {base_url} from '../config/config'
import {FaTimes} from 'react-icons/fa'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, convertFromRaw} from 'draft-js'
import Search from '../assets/icons/search.png'
import {aws_url} from '../config/config'

const MapSideBar = ({closeCB, sideBarData, setSelected}) => {
	const [legends, setLegends] = useState([])
	const [links, setLinks] = useState([])
	const [content, setContent] = useState(EditorState.createEmpty())
	const [filteredLegends, setFilteredLegends] = useState([])
	const [filteredLinks, setFilteredLinks] = useState([])
	const [featuredImage, setFeaturedImage] = useState('')
	const [optionalImages, setOptionalImages] = useState([])
	const [moreImages, setMoreImages] = useState(false)
	const [mapItemContent, setMapItemContent] = useState(EditorState.createEmpty())
	const [searchValue, setSearchValue] = useState()
	const [hasSearchResult, setHasSearchResult] = useState(false)
	const [searchDestinations, setSearchDestinations] = useState([])
	const [searchDistricts, setSearchDistricts] = useState([])
	const [searchLiveAssets, setSearchLiveAssets] = useState([])
	const [searchTrails, setSearchTrails] = useState([])

	useEffect(() => {
		getMenuDetails()
		if (sideBarData) {
			if (sideBarData.featuredImage !== undefined) {
				setFeaturedImage(sideBarData.featuredImage)
			}
			if (sideBarData.optionalImages !== undefined) {
				setOptionalImages(sideBarData.optionalImages)
			}
			setMapItemContent(EditorState.createWithContent(convertFromRaw(JSON.parse(sideBarData.content))))
			if (sideBarData.optionalImages !== undefined) {
				if (sideBarData.optionalImages.length > 1) {
					setMoreImages(true)
				}
			}
		}
	}, [sideBarData])

	useEffect(() => {
		let filterLegends = [];
		if (legends) {
			filterLegends = legends.filter((legend) => {
				return legend.enabled;
			});
			filterLegends.sort((a, b) => {
				return a.order - b.order;
			})
		}
		setFilteredLegends(filterLegends);
	}, [legends])

	useEffect(() => {
		let filterLinks = [];
		if (links) {
			filterLinks = links.filter((link) => {
				return link.enabled;
			});
		}
		setFilteredLinks(filterLinks);
	}, [links])


	const setSearchResult = (val) => {
		setSelected(val)
		setSearchLiveAssets([]);
		setSearchDestinations([]);
		setSearchDistricts([]);
		setSearchTrails([]);
	}
	const getMapData = async () => {
		return await axios
			.get(`${base_url}/user/map_data`)
			.then((response) => {
				if (response.data.success) {
					const destinations = response.data.data.map_data.destinations
					const districts = response.data.data.map_data.districts
					const liveassets = response.data.data.map_data.liveassets
					const trails = response.data.data.map_data.trails
					return {destinations: destinations, districts: districts, liveassets: liveassets, trails: trails}
				}
			})
			.catch(function (error) {
				return error
			})
	}

	const getMenuDetails = async () => {
		await axios
			.get(`${base_url}/user/menu_details`)
			.then((response) => {
				if (response.data.success) {
					setLegends(response.data.data.menu_details.legends)
					setLinks(response.data.data.menu_details.links)
					if (response.data.data.menu_details.subsettings.length > 0) {
						setContent(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.data.menu_details.subsettings[0].content))))
					}
				}
			})
			.catch(function (error) {
				return error
			})
	}

	const changeFeaturedImage = (image, fImage) => {
		if (moreImages) {
			let optImages = optionalImages
			optImages.push(fImage)
			setOptionalImages(optImages)
		}
		setFeaturedImage(image)
		const newImages = optionalImages.filter((i) => i !== image)
		setOptionalImages(newImages)
	}

	const getSearchData = async (evt) => {
		const val = evt.target.value;

		if(val != ''){
			setSearchValue(val);

			//Get all markers information for search
			const mapData = await getMapData()

			//Look through all results for results in names
			let destinations = [];
			mapData.destinations.forEach((el) => {
				if(el.enabled != false) {
					let needle = val.toLowerCase();
					let haystack = el.name.toLowerCase();

					let result = haystack.includes(needle);
					if (result) {
						destinations.push(el)
					}
				}
			})
			setSearchDestinations(destinations);

			let districts = [];
			mapData.districts.forEach((el) => {
				if(el.enabled != false){
					let needle = val.toLowerCase();
					let haystack = el.name.toLowerCase();

					let result = haystack.includes(needle);
					if(result) {
						districts.push(el)
					}
				}
			})
			setSearchDistricts(districts);

			let liveassets = [];
			mapData.liveassets.forEach((el) => {
				if(el.enabled != false){
					let needle = val.toLowerCase();
					let haystack = el.name.toLowerCase();

					let result = haystack.includes(needle);
					if (result) {
						liveassets.push(el)
					}
				}
			})
			setSearchLiveAssets(destinations);

			let trails = [];
			mapData.trails.forEach((el) => {
				if(el.enabled != false){
					let needle = val.toLowerCase();
					let haystack = el.name.toLowerCase();

					let result = haystack.includes(needle);
					if (result) {
						trails.push(el)
					}
				}
			})
			setSearchTrails(trails);

			//set has search result to true to display on screen
			if (destinations !== undefined || destinations.length > 0 ||
				liveassets !== undefined || liveassets.length > 0 ||
				districts !== undefined || districts.length > 0 ||
				trails !== undefined || trails.length > 0) {
				setHasSearchResult(true);
			} else {
				setHasSearchResult(false);
			}
		} else {
			// clear results if empty text
			setSearchValue('');
			setHasSearchResult(false)
			setSearchDestinations([])
			setSearchDistricts([])
			setSearchLiveAssets([])
			setSearchTrails([])

		}
	}

	return (
		<Wrapper>

			<Row style={{overflow: 'auto'}}>
				<List lg={12}>
					<SearchContent>
						<div style={{marginRight:'10px', marginLeft: '-15px'}}>
							<CloseIcon onClick={closeCB} />
						</div>
						<div style={{position: 'relative'}}>
							<TextBox placeholder="Type to Search" value={searchValue} onChange={evt => getSearchData(evt)} />
							<SearchIcon src={Search} />
						</div>
					</SearchContent>
					{sideBarData && (
						<MapDataContainer>
							{featuredImage && <FeaturedImage src={`${aws_url}/${featuredImage}`} />}
							{optionalImages.length > 0 &&
								optionalImages.map((image, index) => {
									return (
										<OptionalImagesContainer key={index}>
											<OptionalImage src={`${aws_url}/${image}`} onClick={(e) => changeFeaturedImage(image, featuredImage)} />
										</OptionalImagesContainer>
									)
								})}
							{sideBarData.name && <MapItemTitle>{sideBarData.name}</MapItemTitle>}
							{sideBarData.type == 'Bus Stop' && <a href="https://embarkok.com" target="_blank" style={{color:'#fff'}}>https://www.embarkok.com</a>}
							{JSON.stringify(mapItemContent).length > 848 && (
								<MapItemContent>
									<Editor editorState={mapItemContent} toolbarClassName="toolbarclass" editorClassName="editorClass" wrapperClassName="wrapperClass" />
								</MapItemContent>
							)}
						</MapDataContainer>
					)}
					{hasSearchResult && (
						<div>
							<ItemList>
								<ItemDetails>
									<h1><b>Search Results:</b></h1>
									{searchDestinations.length > 0 && <h2><b>Destinations</b></h2>}
									{searchDestinations.length > 0 &&
									searchDestinations.map((item, index) => {
										return (
											<div key={index} onClick={() => setSearchResult(item)}>
												<Item>
													<li>{item.name}</li>
												</Item>
											</div>
										)
									})}

									{searchDistricts.length > 0 && <h2><b>Districts</b></h2>}
									{searchDistricts.length > 0 &&
									searchDistricts.map((item, index) => {
										return (
											<div key={index} onClick={() => setSearchResult(item)}>
												<Item>
													<li>{item.name}</li>
												</Item>
											</div>
										)
									})}

									{searchLiveAssets.length > 0 && <h2><b>Live Assets</b></h2>}
									{searchLiveAssets.length > 0 &&
									searchLiveAssets.map((item, index) => {
										return (
											<div key={index} onClick={() => setSearchResult(item)}>
												<Item>
													<li>{item.name}</li>
												</Item>
											</div>
										)
									})}

									{searchTrails.length > 0 && <h2><b>Trails</b></h2>}
									{searchTrails.length > 0 &&
									searchTrails.map((item, index) => {
										return (
											<div key={index} onClick={() => setSearchResult(item)}>
												<Item>
													<li>{item.name}</li>
												</Item>
											</div>
										)
									})}

								</ItemDetails>
							</ItemList>
							<hr/>
						</div>
					)}

					<ItemList>
						<ItemDetails>
						<h1><b>Legend</b></h1>
							{filteredLegends.length > 0 &&
								filteredLegends.map((item, index) => {
									return (
										<div key={index}  style={{cursor: 'default'}}>
											<Item>
												<img src={`${aws_url}/${item.icon}`} alt="item-icon" className="iconClass" />
												<span style={{cursor: 'default'}} >{item.name}</span>
											</Item>
										</div>
									)
								})}
						</ItemDetails>
					</ItemList>
					<ItemList>
						<ItemLink>
							{filteredLinks.length > 0 &&
								filteredLinks.sort(function (a, b) {

									var a1st = -1; // negative value means left item should appear first
									var b1st =  1; // positive value means right item should appear first
									var equal = 0; // zero means objects are equal

									// compare your object's property values and determine their order
									if (b.order < a.order) {
										return b1st;
									}
									else if (a.order < b.order) {
										return a1st;
									}
									else {
										return equal;
									}
								})
								&&
								filteredLinks.map((item, index) => {
									if(item.enabled) {
										return (
											<Routes key={index}>
												<LinkBox>
													<Link href={`${item.url}`} target="_blank" rel="noreferrer noopener">
														<Button>{item.title}</Button>
													</Link>
												</LinkBox>
											</Routes>
										)
									}
								})}
						</ItemLink>
					</ItemList>
					{JSON.stringify(content).length > 848 && (
						<div>
							<ItemList>
								<ItemDetails>
									<EditorBox editorState={content} toolbarClassName="toolbarclass" editorClassName="editorClass" wrapperClassName="wrapperClass" disabled="true" />
								</ItemDetails>
							</ItemList>
						</div>
					)}
					<ItemList>
						<p style={{margin: '15px auto', color: '#fff'}}>Found a bug? <a href="mailto:okcsupport@decibelkc.com" style={{textDecoration: 'underline', color: '#fff'}}>Report it here</a>.</p>
					</ItemList>
				</List>
			</Row>
		</Wrapper>
	)
}

const Wrapper = styled(Container)`
	position: fixed;
	top: 5.12rem;
	bottom: 0;
	right: 0;
	z-index: 20;
	background-color: #89c33c;
	box-shadow: 0 0.1rem 0.3rem 0 #523f690d;
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	width: 18.5rem;
	border-left: 0.5rem solid #2c973f;
	@media screen and (min-width: 0px) and (max-width: 399px) {
		width: 93%;
	}
	@media screen and (min-width: 400px) and (max-width: 500px) {
		width: 94%;
	}
`

const List = styled(Col)`
	width: 100%;
	flex: 1 1 100%;
	padding: 0rem;
	margin: 0rem;
`

const CustomRow = styled(Row)``

const CloseIcon = styled(FaTimes)`
	// display: none;
	color: #fff;
	font-size: 1.6rem;
	cursor: pointer;
`

const BrandContent = styled(Col)`
	padding: 0.42rem 1.7rem;
	display: flex;
	align-items: center;
	justify-content: start;
	cursor: pointer;
	box-sizing: border-box;
`

const MapDataContainer = styled.div`
	// width: 100%;
	margin: 1.6rem;
`

const FeaturedImage = styled.img`
	width: 100%;
`

const OptionalImagesContainer = styled.div`
	// margin: 0 1.6rem;
	width: 100%;
`

const OptionalImage = styled.img`
	border: 1px solid green;
	object-fit: cover;
	float: left;
	height: 4.16rem;
	width: 4.16rem;
	cursor: pointer;
`

const MapItemTitle = styled.h3`
	clear: both;
	margin-top: 1rem;
	color: #fff;
	display: flex;
	justify-content: left;
	font-size: 1.17em;
	font-weight: 600;
`

const MapItemContent = styled.div`
	// width: 84%;
	// @media screen and (max-width: 500px) {
	// 	width: 95%;
	// }
`
const ItemHeading = styled.h6`
	width: 100%;
	margin-bottom: 0.2rem;
`

const ItemDetails = styled.div``
const ItemLink = styled.div`
	padding: 0px 0px;
`

const Text = styled.i`
	
    font-size: 1.5rem !important;
	font-style: normal;
	font-weight: bold;
	color: white;
	
`

const LinkBox = styled.div`
	width: 100%;

`
const Link = styled.a`
	width: 100%;
	color: #fff;
	text-decoration: none;
	
`

const Item = styled.button`
	background: none;
	border: none;
	border-radius: 4px;
	margin: 4px;
	cursor: pointer;
	width: 100%;
	font-size: 1rem !important;
	font-style: normal;
	color: #fff;
	display: flex;
	justify-content: left;
	align-items: center;
`

const Flex = styled(Text)`
	margin-left: 1.5rem;
	display: flex;
	text-align: left;
`

const PhoneText = styled(Flex)`
	padding: 16px 0px;
	font-size: 1.5rem !important;
`

const Routes = styled.div`
	text-align: left;
	font-weight: 500;
	margin: 25px auto;
	box-sizing: border-box;
	width: 100%;
	display: flex;
`

const ItemList = styled.div`
	padding: 0px 24px;
	text-align: left;
`

const SearchContent = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	text-align: left;
	font-weight: 500;
	padding: 16px 24px;
	box-sizing: border-box;
	width: 100%;
`

const SearchIcon = styled.img`
	position: absolute;
	right: 4px;
	background: white;
	width: 1.7rem;
	top: 4px;
`

const Button = styled.button`
	font-size: 1.12rem !important;
	font-style: normal;
	font-weight: bold;
	color: white;
	padding: 1rem;
	text-align: center;
	border: 0.1rem solid black;
	text-transform: uppercase;
	background: linear-gradient(#89c33c 50%, #669b1e 100%);
	cursor: pointer;
	width: 100%;
	@media screen and (max-width: 500px) {
		width: 100%;
	}
`

const TextBox = styled.input`
	width: 100%;
	color: #669b1e;
	font-size: 1rem;
	padding: 0.3rem 2rem .3rem .3rem;
	::placeholder {
		color: #669b1e;
		opacity: 1; /* Firefox */
		font-size: 1rem;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #669b1e;
		font-size: 1rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #669b1e;
		font-size: 1rem;
	}
`

const EditorBox = styled(Editor)`
	width: 100%;
	border: 1px solid green;
`

export default MapSideBar
