import './App.css'
import React, {useState, useEffect} from 'react'
import Header from './components/Header'
import MapContainer from './components/MapContainer'

function App() {
	const [latitude, setLatitude] = useState(35.460417)
	const [longitude, setLongitude] = useState(-97.25625)

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(function (position) {
			setLongitude(position.coords.longitude)
			setLatitude(position.coords.latitude)
		})
	}, [])

	return (
		<div className="App">
			<Header />
			<MapContainer latitude={latitude} longitude={longitude} />
		</div>
	)
}

export default App
